// Name:            Badge
// Description:     Defines styles for badges
//
// Component:       `uk-badge`
//
// Modifiers:       `uk-badge-notification`
//                  `uk-badge-success`
//                  `uk-badge-danger`
//
// ========================================================================


// Variables
// ========================================================================

//@badge-background:                              #00a8e6;
//@badge-padding-horizontal:                      5px;
//@badge-font-size:                               10px;
//@badge-font-weight:                             bold;
//@badge-line-height:                             14px;
//@badge-color:                                   #fff;
//@badge-text-transform:                          none;
//@badge-hover-color:                             #fff;
//
//@badge-notification-font-size:                  12px;
//@badge-notification-line-height:                18px;
//
//@badge-success-background:                      #8cc14c;
//@badge-warning-background:                      #faa732;
//@badge-danger-background:                       #da314b;


/* ========================================================================
   Component: Badge
 ========================================================================== */

.uk-badge {
    display: inline-block;
    padding: 0 @badge-padding-horizontal;
    background: @badge-background;
    font-size: @badge-font-size;
    font-weight: @badge-font-weight;
    line-height: @badge-line-height;
    color: @badge-color;
    text-align: center;
    vertical-align: middle;
    text-transform: @badge-text-transform;
    .hook-badge;
}

/*
 * Keep color when badge is a link
 */

a.uk-badge:hover { color: @badge-hover-color; }


/* Modifier: `uk-badge-notification`;
 ========================================================================== */

.uk-badge-notification {
    box-sizing: border-box;
    min-width: @badge-notification-line-height;
    border-radius: 500px;
    font-size: @badge-notification-font-size;
    line-height: @badge-notification-line-height;
}


/* Color modifier
 ========================================================================== */

/*
 * Modifier: `uk-badge-success`
 */

.uk-badge-success {
    background-color: @badge-success-background;
    .hook-badge-success;
}

/*
 * Modifier: `uk-badge-warning`
 */

.uk-badge-warning {
    background-color: @badge-warning-background;
    .hook-badge-warning;
}

/*
 * Modifier: `uk-badge-danger`
 */

.uk-badge-danger {
    background-color: @badge-danger-background;
    .hook-badge-danger;
}


// Hooks
// ========================================================================

.hook-badge-misc;

.hook-badge() {}
.hook-badge-success() {}
.hook-badge-warning() {}
.hook-badge-danger() {}
.hook-badge-misc() {}