// Name:            Alert
// Description:     Defines styles for alert messages
//
// Component:       `uk-alert`
//
// Sub-objects:     `uk-alert-close`
//
// Modifiers:       `uk-alert-success`
//                  `uk-alert-warning`
//                  `uk-alert-danger`
//                  `uk-alert-large`
//
// Uses:            Close: `uk-close`
//
// Markup:
//
// <!-- uk-alert -->
// <div class="uk-alert" data-uk-alert>
//     <a href="#" class="uk-alert-close uk-close"></a>
//     <p></p>
// </div>
//
// ========================================================================


// Variables
// ========================================================================

//@alert-margin-vertical:                         15px;
//@alert-padding:                                 10px;
//@alert-background:                              #ebf7fd;
//@alert-color:                                   #2d7091;
//
//@alert-success-background:                      #f2fae3;
//@alert-success-color:                           #659f13;
//
//@alert-warning-background:                      #fffceb;
//@alert-warning-color:                           #e28327;
//
//@alert-danger-background:                       #fff1f0;
//@alert-danger-color:                            #d85030;
//
//@alert-large-padding:                           20px;


/* ========================================================================
   Component: Alert
 ========================================================================== */

.uk-alert {
    margin-bottom: @alert-margin-vertical;
    padding: @alert-padding;
    background: @alert-background;
    color: @alert-color;
    .hook-alert;
}

/*
 * Add margin if adjacent element
 */

* + .uk-alert { margin-top: @alert-margin-vertical; }

/*
 * Remove margin from the last-child
 */

.uk-alert > :last-child { margin-bottom: 0; }

/*
 * Keep color for headings if the default heading color is changed
 */

.uk-alert h1,
.uk-alert h2,
.uk-alert h3,
.uk-alert h4,
.uk-alert h5,
.uk-alert h6 { color: inherit; }


/* Close in alert
 ========================================================================== */

.uk-alert > .uk-close:first-child { float: right; }

/*
 * Remove margin from adjacent element
 */

.uk-alert > .uk-close:first-child + * { margin-top: 0; }


/* Modifier: `uk-alert-success`
 ========================================================================== */

.uk-alert-success {
    background: @alert-success-background;
    color: @alert-success-color;
    .hook-alert-success;
}


/* Modifier: `uk-alert-warning`
 ========================================================================== */

.uk-alert-warning {
    background: @alert-warning-background;
    color: @alert-warning-color;
    .hook-alert-warning;
}


/* Modifier: `uk-alert-danger`
 ========================================================================== */

.uk-alert-danger {
    background: @alert-danger-background;
    color: @alert-danger-color;
    .hook-alert-danger;
}


/* Modifier: `uk-alert-large`
 ========================================================================== */

.uk-alert-large { padding: @alert-large-padding; }

.uk-alert-large > .uk-close:first-child { margin: -10px -10px 0 0; }


// Hooks
// ========================================================================

.hook-alert-misc;

.hook-alert() {}
.hook-alert-success() {}
.hook-alert-warning() {}
.hook-alert-danger() {}
.hook-alert-misc() {}