@accordion-title-margin-bottom:                 15px;
@accordion-title-padding-vertical:              5px;
@accordion-title-padding-horizontal:            15px;
@accordion-title-font-size:                     18px;
@accordion-title-line-height:                   24px;
@accordion-title-background:                    #eee;
@accordion-content-padding-horizontal:          @accordion-title-padding-horizontal;
@accordion-content-padding-bottom:              @accordion-title-padding-horizontal;
@nav-autocomplete-color:                        #444;
@nav-autocomplete-active-background:            #00a8e6;
@nav-autocomplete-active-color:                 #fff;
@nav-autocomplete-header-color:                 #999;
@nav-autocomplete-divider-border:               #ddd;
@nav-autocomplete-divider-border-width:         1px;
@datepicker-z-index:                            1050;
@datepicker-animation:                          uk-fade;
@datepicker-nav-height:                         20px;
@datepicker-nav-margin-bottom:                  15px;
@datepicker-nav-color:                          #444;
@datepicker-nav-hover-color:                    #444;
@datepicker-previous-icon:                      "\f053";
@datepicker-next-icon:                          "\f054";
@datepicker-table-width:                        26px;
@datepicker-table-height:                       24px;
@datepicker-table-color:                        #444;
@datepicker-table-hover-background:             #ddd;
@datepicker-table-hover-color:                  #444;
@datepicker-table-onclick-background:           #ccc;
@datepicker-table-onclick-color:                #444;
@datepicker-table-active-background:            #00a8e6;
@datepicker-table-active-color:                 #fff;
@datepicker-table-muted-color:                  #999;
@dotnav-margin-horizontal:                      15px;
@dotnav-margin-vertical:                        @dotnav-margin-horizontal;
@dotnav-width:                                  20px;
@dotnav-height:                                 @dotnav-width;
@dotnav-background:                             rgba(50,50,50,0.1);
@dotnav-hover-background:                       rgba(50,50,50,0.4);
@dotnav-onclick-background:                     rgba(50,50,50,0.6);
@dotnav-active-background:                      rgba(50,50,50,0.4);
@dotnav-contrast-background:                    rgba(255,255,255,0.4);
@dotnav-contrast-hover-background:              rgba(255,255,255,0.7);
@dotnav-contrast-onclick-background:            rgba(255,255,255,0.9);
@dotnav-contrast-active-background:             rgba(255,255,255,0.9);
@form-advanced-size:                          14px;
@form-advanced-border:                        #aaa;
@form-advanced-border-width:                  1px;
@form-advanced-margin-top:                    -4px;
@form-advanced-color:                         transparent;
@form-advanced-checked-color:                 #00a8e6;
@form-advanced-radio-size:                    8px;
@form-advanced-checkbox-icon:                 "\f00c";
@form-advanced-checkbox-indeterminate-icon:   "\f068";
@form-advanced-checkbox-font-size:            12px;
@form-advanced-disabled-border:               #ddd;
@form-advanced-disabled-color:                #aaa;
@form-password-font-size:                       13px;
@form-password-line-height:                     @form-password-font-size;
@form-password-color:                           #999;
@form-password-hover-color:                     #999;
@form-password-padding:                         50px;
@htmleditor-navbar-background:                  #eee;
@htmleditor-navbar-nav-height:                  40px;
@htmleditor-navbar-nav-padding-horizontal:      15px;
@htmleditor-navbar-nav-line-height:             @htmleditor-navbar-nav-height;
@htmleditor-navbar-nav-color:                   #444;
@htmleditor-navbar-nav-hover-background:        #f5f5f5;
@htmleditor-navbar-nav-hover-color:             #444;
@htmleditor-navbar-nav-onclick-background:      #ddd;
@htmleditor-navbar-nav-onclick-color:           #444;
@htmleditor-navbar-nav-active-background:       #f5f5f5;
@htmleditor-navbar-nav-active-color:            #444;
@htmleditor-content-border:                     #ddd;
@htmleditor-content-background:                 #fff;
@htmleditor-content-padding:                    20px;
@htmleditor-content-split-border:               #eee;
@htmleditor-fullscreen-z-index:                 990;
@htmleditor-fullscreen-content-top:             @htmleditor-navbar-nav-height;
@htmleditor-fullscreen-icon-resize:             "\f066";
@nestable-padding-left:                         40px;
@nestable-item-margin:                          10px;
@nestable-dragged-z-index:                      1050;
@nestable-placeholder-border:                   #ddd;
@nestable-empty-height:                         30px;
@nestable-toggle-icon:                          "\f147";
@nestable-toggle-collapsed-icon:                "\f196";
@nestable-panel-padding:                        5px;
@nestable-panel-background:                     #f5f5f5;
@notify-position:                               10px;
@notify-z-index:                                1040;
@notify-width:                                  350px;
@notify-message-margin-bottom:                  10px;
@notify-message-padding:                        15px;
@notify-message-background:                     #444;
@notify-message-color:                          #fff;
@notify-message-font-size:                      16px;
@notify-message-line-height:                    22px;
@notify-message-primary-background:             #ebf7fd;
@notify-message-primary-color:                  #2d7091;
@notify-message-success-background:             #f2fae3;
@notify-message-success-color:                  #659f13;
@notify-message-warning-background:             #fffceb;
@notify-message-warning-color:                  #e28327;
@notify-message-danger-background:              #fff1f0;
@notify-message-danger-color:                   #d85030;
@placeholder-margin-vertical:                   15px;
@placeholder-padding:                           20px;
@placeholder-border:                            #ddd;
@placeholder-background:                        #fafafa;
@placeholder-color:                             #444;
@placeholder-large-padding-vertical:            80px;
@progress-height:                               20px;
@progress-mini-height:                          6px;
@progress-small-height:                         12px;
@progress-margin-vertical:                      15px;
@progress-background:                           #eee;
@progress-bar-background:                       #00a8e6;
@progress-bar-font-size:                        12px;
@progress-bar-color:                            #fff;
@progress-bar-success-background:               #8cc14c;
@progress-bar-warning-background:               #faa732;
@progress-bar-danger-background:                #da314b;
@search-width:                                  310px;
@search-focus-width:                            310px;
@search-height:                                 40px;
@search-padding:                                30px;
@search-border:                                 rgba(0,0,0,0);
@search-border-width:                           1px;
@search-border-radius:							5px;
@search-background:                             #fff;
@search-color:                                  #c0beb4;
@search-placeholder-color:                      #999;
@search-icon:                                   "\f002";
@search-icon-size:                              14px;
@search-icon-color:                             rgba(0,0,0,0.2);
@dropdown-search-width:                         300px;
@dropdown-search-margin-top:                    0;
@dropdown-search-background:                    #f5f5f5;
@dropdown-search-color:                         #444;
@dropdown-search-animation:                     uk-slide-top-fixed;
@dropdown-search-navbar-margin-top:             5px;
@dropdown-search-navbar-margin-right:           -15px;
@nav-search-color:                              #444;
@nav-search-active-background:                  #00a8e6;
@nav-search-active-color:                       #fff;
@nav-search-header-color:                       #999;
@nav-search-divider-border:                     #ddd;
@nav-search-divider-border-width:               1px;
@nav-search-nested-color:                       #07D;
@nav-search-nested-hover-color:                 #059;
@offcanvas-search-margin:                       20px 15px;
@offcanvas-search-background:                   #1a1a1a;
@offcanvas-search-border:                       rgba(0,0,0,0);
@offcanvas-search-color:                        #ccc;
@offcanvas-search-placeholder-color:            #777;
@offcanvas-search-icon-color:                   #777;
@slidenav-width:                                60px;
@slidenav-height:                               @slidenav-width;
@slidenav-color:                                rgba(50,50,50,0.4);
@slidenav-font-size:                            60px;
@slidenav-line-height:                          @slidenav-height;
@slidenav-previous-icon:                        "\f104";
@slidenav-next-icon:                            "\f105";
@slidenav-hover-color:                          rgba(50,50,50,0.7);
@slidenav-active-color:                         rgba(50,50,50,0.9);
@slidenav-position-previous:                    20px;
@slidenav-position-next:                        @slidenav-position-previous;
@slidenav-contrast-color:                       rgba(255,255,255,0.5);
@slidenav-contrast-hover-color:                 rgba(255,255,255,0.7);
@slidenav-contrast-active-color:                rgba(255,255,255,0.9);
@sortable-dragged-z-index:                      1050;
@sortable-placeholder-opacity:                  0;
@sortable-empty-height:                         30px;
@sticky-z-index:                                980;
@sticky-animation-duration:                     0.2s;
@sticky-reverse-animation-duration:             0.2s;
@tooltip-z-index:                               1030;
@tooltip-max-width:                             200px;
@tooltip-padding-vertical:                      5px;
@tooltip-padding-horizontal:                    8px;
@tooltip-background:                            #333;
@tooltip-triangle-size:                         5px;
@tooltip-color:                                 rgba(255,255,255,0.7);
@tooltip-font-size:                             12px;
@tooltip-line-height:                           18px;
@tooltip-offset-horizontal:                     10px;
@uk-upload-dragover-box-shadow:                 rgba(100,100,100,0.3);
@uk-upload-dragover-box-shadow-blur:            20px;
@alert-margin-vertical:                         15px;
@alert-padding:                                 10px;
@alert-background:                              #ebf7fd;
@alert-color:                                   #2d7091;
@alert-success-background:                      #f2fae3;
@alert-success-color:                           #659f13;
@alert-warning-background:                      #fffceb;
@alert-warning-color:                           #e28327;
@alert-danger-background:                       #fff1f0;
@alert-danger-color:                            #d85030;
@alert-large-padding:                           20px;
@article-margin-top:                            25px;
@article-title-font-size:                       36px;
@article-title-line-height:                     42px;
@article-title-font-weight:                     normal;
@article-title-text-transform:                  none;
@article-meta-font-size:                        12px;
@article-meta-line-height:                      18px;
@article-meta-color:                            #999;
@article-lead-color:                            #444;
@article-lead-font-size:                        18px;
@article-lead-line-height:                      24px;
@article-lead-font-weight:                      normal;
@article-divider-margin:                        25px;
@article-divider-border:                        #ddd;
@badge-background:                              #00a8e6;
@badge-padding-horizontal:                      5px;
@badge-font-size:                               10px;
@badge-font-weight:                             bold;
@badge-line-height:                             14px;
@badge-color:                                   #fff;
@badge-text-transform:                          none;
@badge-hover-color:                             #fff;
@badge-notification-font-size:                  12px;
@badge-notification-line-height:                18px;
@badge-success-background:                      #8cc14c;
@badge-warning-background:                      #faa732;
@badge-danger-background:                       #da314b;
@base-body-background:                          #fff;
@base-body-font-family:                         "bh-pt-sans", sans-serif;
@base-body-font-weight:                         normal;
@base-html-font-size:                           12px;
@base-html-line-height:                         1.66667em;

@base-body-font-size-rem:                       1rem;
@base-body-font-size-px:                        12px;
@base-body-line-height:                         @base-html-line-height;

@base-body-color:                               #444;
@base-link-color:                               #212223;
@base-link-text-decoration:                     none;
@base-link-hover-color:                         #a20f12;
@base-link-hover-text-decoration:               underline;
@base-link-active-color:                        #749fa6;
@base-code-color:                               #D05;
@base-code-font-size:                           12px;
@base-code-font-family:                         Consolas, monospace, serif;
@base-em-color:                                 #D05;
@base-ins-background:                           #ffa;
@base-ins-color:                                #444;
@base-mark-background:                          #ffa;
@base-mark-color:                               #444;
@base-quote-font-style:                         italic;
@base-margin-vertical:                          15px;
@base-heading-font-family:                      "bh-crimson", serif;
@base-heading-font-weight:                      normal;
@base-heading-color:                            #444;
@base-heading-text-transform:                   none;
@base-heading-margin-top:                       25px;

@base-h1-font-size:                             2.5em;
@base-h1-line-height:                           1.2em;

@base-h2-font-size:                             1.833em;
@base-h2-line-height:                           1.25em;

@base-h3-font-size:                             1.66667em;
@base-h3-line-height:                           1.25em;

@base-h4-font-size:                             1.5em;
@base-h4-line-height:                           1.25em;

@base-h5-font-size:                             1.66667em;
@base-h5-line-height:                           1.25em;

@base-h6-font-size:                             1em;
@base-h6-line-height:                           1.25em;

@base-list-padding-left:                        30px;
@base-hr-margin-vertical:                       @base-margin-vertical;
@base-hr-border:                                #ddd;
@base-hr-border-width:                          1px;
@base-blockquote-padding-left:                  15px;
@base-blockquote-border:                        #ddd;
@base-blockquote-border-width:                  5px;
@base-blockquote-font-size:                     16px;
@base-blockquote-line-height:                   22px;
@base-blockquote-font-style:                    italic;
@base-pre-padding:                              10px;
@base-pre-background:                           #f5f5f5;
@base-pre-color:                                #444;
@base-pre-font-size:                            12px;
@base-pre-line-height:                          18px;
@base-pre-font-family:                          @base-code-font-family;
@base-selection-background:                     #39f;
@base-selection-color:                          #fff;
@block-padding-vertical:                        20px;
@block-padding-vertical-large:                  50px;
@block-large-padding-vertical:                  @block-padding-vertical;
@block-large-padding-vertical-medium:           @block-padding-vertical-large;
@block-large-padding-vertical-large:            100px;
@block-default-background:                      #fff;
@block-muted-background:                        #f9f9f9;
@block-primary-background:                      #00a8e6;
@block-secondary-background:                    #222;
@breadcrumb-font-size:                          1rem;
@breadcrumb-divider:                            "/";
@breadcrumb-divider-margin:                     8px;
@breadcrumb-disabled-color:                     #999;
@button-height:                                 30px;
@button-mini-height:                            20px;
@button-small-height:                           25px;
@button-large-height:                           40px;
@button-line-height:                            30px;
@button-mini-line-height:                       20px;
@button-small-line-height:                      25px;
@button-large-line-height:                      40px;
@button-mini-font-size:                         11px;
@button-small-font-size:                        12px;
@button-large-font-size:                        16px;
@button-padding-horizontal:                     12px;
@button-mini-padding-horizontal:                6px;
@button-small-padding-horizontal:               10px;
@button-large-padding-horizontal:               15px;
@button-font-size:                              1rem;
@button-background:                             #eee;
@button-color:                                  #444;
@button-hover-background:                       #f5f5f5;
@button-hover-color:                            #444;
@button-active-background:                      #ddd;
@button-active-color:                           #444;
@button-primary-background:                     #00a8e6;
@button-primary-color:                          #fff;
@button-primary-hover-background:               #35b3ee;
@button-primary-hover-color:                    #fff;
@button-primary-active-background:              #0091ca;
@button-primary-active-color:                   #fff;
@button-success-background:                     #8cc14c;
@button-success-color:                          #fff;
@button-success-hover-background:               #8ec73b;
@button-success-hover-color:                    #fff;
@button-success-active-background:              #72ae41;
@button-success-active-color:                   #fff;
@button-danger-background:                      #da314b;
@button-danger-color:                           #fff;
@button-danger-hover-background:                #e4354f;
@button-danger-hover-color:                     #fff;
@button-danger-active-background:               #c91032;
@button-danger-active-color:                    #fff;
@button-disabled-background:                    #f5f5f5;
@button-disabled-color:                         #999;
@button-link-color:                             #07D;
@button-link-hover-color:                       #059;
@button-link-hover-text-decoration:             underline;
@button-link-disabled-color:                    #999;
@close-size:                                    20px;
@close-alt-padding:                             2px;
@close-alt-background:                          #eee;
@column-gutter:                                 25px;
@comment-header-margin-bottom:                  15px;
@comment-avatar-margin-right:                   15px;
@comment-title-margin-top:                      5px;
@comment-title-font-size:                       16px;
@comment-title-line-height:                     22px;
@comment-meta-margin-top:                       2px;
@comment-meta-color:                            #999;
@comment-meta-font-size:                        11px;
@comment-meta-line-height:                      16px;
@comment-list-margin-top:                       15px;
@comment-list-padding-left:                     100px;
@contrast-color:                                #fff;
@contrast-inverted-color:                       #444;
@contrast-base-color:                           @contrast-color;
@contrast-base-link-color:                      fade(@contrast-color, 70%);
@contrast-base-link-text-decoration:            none;
@contrast-base-link-hover-color:                @contrast-color;
@contrast-base-link-hover-text-decoration:      underline;
@contrast-base-code-color:                      @contrast-color;
@contrast-base-em-color:                        @contrast-color;
@contrast-base-heading-color:                   @contrast-color;
@contrast-base-hr-border:                       fade(@contrast-color, 20%);
@contrast-nav-side-color:                       @contrast-color;
@contrast-nav-side-hover-background:            fade(@contrast-color, 10%);
@contrast-nav-side-hover-color:                 @contrast-color;
@contrast-nav-side-active-background:           @contrast-color;
@contrast-nav-side-active-color:                @contrast-inverted-color;
@contrast-nav-side-header-color:                @contrast-color;
@contrast-nav-side-divider-border:              fade(@contrast-color, 20%);
@contrast-nav-side-nested-color:                fade(@contrast-color, 70%);
@contrast-nav-side-nested-hover-color:          @contrast-color;
@contrast-subnav-color:                         fade(@contrast-color, 70%);
@contrast-subnav-hover-color:                   @contrast-color;
@contrast-subnav-hover-text-decoration:         none;
@contrast-subnav-active-color:                  @contrast-color;
@contrast-subnav-line-border:                   fade(@contrast-color, 20%);
@contrast-subnav-pill-hover-background:         fade(@contrast-color, 70%);
@contrast-subnav-pill-hover-color:              @contrast-inverted-color;
@contrast-subnav-pill-active-background:        @contrast-color;
@contrast-subnav-pill-active-color:             @contrast-inverted-color;
@contrast-list-line-border:                     fade(@contrast-color, 20%);
@contrast-form-border:                          fade(@contrast-color, 80%);
@contrast-form-background:                      fade(@contrast-color, 80%);
@contrast-form-color:                           @contrast-inverted-color;
@contrast-form-focus-border:                    @contrast-color;
@contrast-form-focus-background:                @contrast-color;
@contrast-form-focus-color:                     @contrast-inverted-color;
@contrast-form-placeholder-color:               fade(@contrast-inverted-color, 70%);
@contrast-button-background:                    @contrast-color;
@contrast-button-color:                         @contrast-inverted-color;
@contrast-button-hover-background:              fade(@contrast-color, 80%);
@contrast-button-hover-color:                   @contrast-inverted-color;
@contrast-button-active-background:             fade(@contrast-color, 70%);
@contrast-button-active-color:                  @contrast-inverted-color;
@contrast-button-primary-background:            #00a8e6;
@contrast-button-primary-color:                 @contrast-color;
@contrast-button-primary-hover-background:      #35b3ee;
@contrast-button-primary-hover-color:           @contrast-color;
@contrast-button-primary-active-background:     #0091ca;
@contrast-button-primary-active-color:          @contrast-color;
@contrast-icon-hover-color:                     fade(@contrast-color, 70%);
@contrast-icon-hover-hover-color:               @contrast-color;
@contrast-icon-button-background:               @contrast-color;
@contrast-icon-button-color:                    @contrast-inverted-color;
@contrast-icon-button-hover-background:         fade(@contrast-color, 80%);
@contrast-icon-button-hover-color:              @contrast-inverted-color;
@contrast-icon-button-active-background:        fade(@contrast-color, 70%);
@contrast-icon-button-active-color:             @contrast-inverted-color;
@contrast-text-muted-color:                     fade(@contrast-color, 60%);
@contrast-text-primary-color:                   #2d7091;
@description-list-horizontal-width:             160px;
@description-list-horizontal-margin-left:       180px;
@description-list-line-margin-top:              5px;
@description-list-line-border-width:            1px;
@description-list-line-border:                  #ddd;
@description-list-line-color:                   #999;
@description-list-line-font-weight:             normal;
@dropdown-z-index:                              970;
@dropdown-width:                                200px;
@dropdown-margin-top:                           5px;
@dropdown-padding:                              15px;
@dropdown-background:                           #f5f5f5;
@dropdown-color:                                #444;
@dropdown-font-size:                            1rem;
@dropdown-animation:                            uk-fade;
@dropdown-divider-border-width:                 1px;
@dropdown-divider-border:                       #ddd;
@dropdown-small-padding:                        5px;
@dropdown-navbar-margin:                        0;
@dropdown-navbar-background:                    #f5f5f5;
@dropdown-navbar-color:                         #444;
@dropdown-navbar-animation:                     uk-slide-top-fixed;
@dropdown-scrollable-height:                    200px;
@form-height:                                   30px;
@form-padding:                                  4px 6px;
@form-border:                                   #ddd;
@form-border-width:                             1px;
@form-background:                               #fff;
@form-color:                                    #444;
@form-focus-border:                             #99baca;
@form-focus-background:                         #f5fbfe;
@form-focus-color:                              #444;
@form-disabled-border:                          #ddd;
@form-disabled-background:                      #f5f5f5;
@form-disabled-color:                           #999;
@form-placeholder-color:                        #999;
@form-gutter:                                   15px;
@form-legend-border:                            #ddd;
@form-legend-border-width:                      1px;
@form-legend-font-size:                         18px;
@form-legend-line-height:                       30px;
@form-small-height:                             25px;
@form-large-height:                             40px;
@form-small-padding:                            3px 3px;
@form-large-padding:                            8px 6px;
@form-small-font-size:                          12px;
@form-large-font-size:                          16px;
@form-danger-border:                            #dc8d99;
@form-danger-background:                        #fff7f8;
@form-danger-color:                             #d85030;
@form-success-border:                           #8ec73b;
@form-success-background:                       #fafff2;
@form-success-color:                            #659f13;
@form-blank-border:                             #ddd;
@form-blank-border-style:                       dashed;
@form-mini-width:                               40px;
@form-small-width:                              130px;
@form-medium-width:                             200px;
@form-large-width:                              500px;
@form-help-inline-margin-left:                  10px;
@form-help-block-margin-top:                    5px;
@form-controls-condensed-margin-vertical:       5px;
@form-stacked-margin-bottom:                    5px;
@form-stacked-font-weight:                      bold;
@form-horizontal-label-width:                   200px;
@form-horizontal-label-margin-top:              5px;
@form-horizontal-controls-margin-left:          215px;
@form-horizontal-controls-text-padding-top:     5px;
@form-icon-width:                               30px;
@form-icon-font-size:                           14px;
@form-icon-color:                               #999;
@grid-gutter-horizontal:                        25px;
@grid-gutter-vertical:                          25px;
@grid-gutter-large-horizontal:                  35px;
@grid-gutter-large-vertical:                    35px;
@grid-gutter-small-horizontal:                  10px;
@grid-gutter-small-vertical:                    10px;
@grid-divider-border:                           #ddd;
@grid-divider-border-width:                     1px;
@icon-font-path:                                "../fonts";
@icon-small-font-size:                          150%;
@icon-medium-font-size:                         200%;
@icon-large-font-size:                          250%;
@icon-small-vertical-align:                     -10%;
@icon-medium-vertical-align:                    -16%;
@icon-large-vertical-align:                     -22%;
@icon-hover-color:                              #999;
@icon-hover-hover-color:                        #444;
@icon-button-width:                             35px;
@icon-button-height:                            @icon-button-width;
@icon-button-border-radius:                     100%;
@icon-button-background:                        #eee;
@icon-button-font-size:                         round((@icon-button-width * 0.5));
@icon-button-color:                             #444;
@icon-button-hover-background:                  #f5f5f5;
@icon-button-hover-color:                       #444;
@icon-button-active-background:                 #ddd;
@icon-button-active-color:                      #444;
@list-nested-padding-left:                      20px;
@list-line-margin-top:                          5px;
@list-line-border:                              #ddd;
@list-line-border-width:                        1px;
@list-striped-padding-vertical:                 5px;
@list-striped-padding-horizontal:               5px;
@list-striped-background:                       #f5f5f5;
@list-space-margin-top:                         10px;
@modal-z-index:                                 1010;
@modal-background:                              rgba(0,0,0,0.6);
@modal-dialog-margin-vertical:                  50px;
@modal-dialog-padding:                          20px;
@modal-dialog-width:                            600px;
@modal-dialog-background:                       #fff;
@modal-dialog-large-width:                      930px;
@modal-dialog-large-width-large:                1130px;
@modal-header-margin-bottom:                    15px;
@modal-footer-margin-top:                       15px;
@modal-caption-margin-bottom:                   -10px;
@modal-caption-color:                           #fff;
@nav-padding-vertical:                          5px;
@nav-padding-horizontal:                        15px;
@nav-header-padding-vertical:                   @nav-padding-vertical;
@nav-header-padding-horizontal:                 @nav-padding-horizontal;
@nav-header-font-size:                          12px;
@nav-header-font-weight:                        bold;
@nav-header-text-transform:                     uppercase;
@nav-header-margin-top:                         15px;
@nav-divider-margin-vertical:                   9px;
@nav-divider-margin-horizontal:                 @nav-padding-horizontal;
@nav-subtitle-font-size:                        12px;
@nav-subtitle-line-height:                      18px;
@nav-nested-padding-left:                       15px;
@nav-nested-padding-vertical:                   2px;
@nav-nested-padding-horizontal:                 0;
@nav-sub-padding-vertical:                      5px;
@nav-sub-padding-left:                          @nav-padding-horizontal;
@nav-parent-icon:                               "\f104";
@nav-parent-open-icon:                          "\f107";
@nav-parent-icon-width:                         20px;
@nav-parent-icon-margin-right:                  -10px;
@nav-side-color:                                #444;
@nav-side-hover-background:                     rgba(0,0,0,0.05);
@nav-side-hover-color:                          #444;
@nav-side-active-background:                    #00a8e6;
@nav-side-active-color:                         #fff;
@nav-side-header-color:                         #444;
@nav-side-divider-border:                       #ddd;
@nav-side-divider-border-width:                 1px;
@nav-side-nested-color:                         #07D;
@nav-side-nested-hover-color:                   #059;
@nav-dropdown-color:                            #444;
@nav-dropdown-hover-background:                 #00a8e6;
@nav-dropdown-hover-color:                      #fff;
@nav-dropdown-header-color:                     #999;
@nav-dropdown-divider-border:                   #ddd;
@nav-dropdown-divider-border-width:             1px;
@nav-dropdown-nested-color:                     #07D;
@nav-dropdown-nested-hover-color:               #059;
@nav-navbar-color:                              #444;
@nav-navbar-hover-background:                   #00a8e6;
@nav-navbar-hover-color:                        #fff;
@nav-navbar-header-color:                       #999;
@nav-navbar-divider-border:                     #ddd;
@nav-navbar-divider-border-width:               1px;
@nav-navbar-nested-color:                       #07D;
@nav-navbar-nested-hover-color:                 #059;
@nav-offcanvas-padding-vertical:                10px;
@nav-offcanvas-padding-horizontal:              15px;
@nav-offcanvas-color:                           #ccc;
@nav-offcanvas-hover-background:                #404040;
@nav-offcanvas-hover-color:                     #fff;
@nav-offcanvas-active-background:               #1a1a1a;
@nav-offcanvas-active-color:                    #fff;
@nav-offcanvas-header-color:                    #777;
@nav-offcanvas-divider-border:                  #1a1a1a;
@nav-offcanvas-divider-border-width:            1px;
@nav-offcanvas-nested-color:                    #ccc;
@nav-offcanvas-nested-hover-color:              #fff;
@navbar-background:                             #2d2d26;
@navbar-color:                                  #c0c0c0;
@navbar-link-color:                             #c0c0c0;
@navbar-link-hover-color:                       #fff;
@navbar-nav-height:                             80px;
@navbar-nav-line-height:                        @navbar-nav-height;
@navbar-nav-padding-horizontal:                 38px;
@navbar-nav-text-transform:    					uppercase;
@navbar-nav-color:                              #c0c0c0;
@navbar-nav-font-size:                          13px;
@navbar-nav-font-weight:                        normal;
@navbar-nav-font-family:                        "bh-pt-sans", sans-serif;
@navbar-nav-hover-background:                   #555553;
@navbar-nav-hover-color:                        #c0c0c0;
@navbar-nav-onclick-background:                 #ddd;
@navbar-nav-onclick-color:                      #444;
@navbar-nav-active-background:                  #f5f5f5;
@navbar-nav-active-color:                       #444;
@navbar-nav-subtitle-font-size:                 10px;
@navbar-nav-subtitle-offset:                    2px;
@navbar-brand-font-size:                        18px;
@navbar-brand-color:                            #444;
@navbar-brand-hover-color:                      #444;
@navbar-toggle-font-size:                       18px;
@navbar-toggle-color:                           #444;
@navbar-toggle-hover-color:                     #444;
@navbar-toggle-icon:                            "\f0c9";
@navbar-toggle-icon-alt:                        "\f002";
@offcanvas-z-index:                             1000;
@offcanvas-background:                          rgba(0,0,0,0.1);
@offcanvas-bar-width:                           270px;
@offcanvas-bar-background:                      #333;
@offcanvas-panel-margin:                        20px 15px;
@offcanvas-panel-color:                         #777;
@offcanvas-panel-link-color:                    #ccc;
@offcanvas-panel-link-hover-color:              #fff;
@offcanvas-panel-title-color:                   @offcanvas-panel-link-color;
@overlay-panel-padding:                         20px;
@overlay-panel-color:                           #fff;
@overlay-panel-background:                      rgba(0,0,0,0.5);
@overlay-icon:                                  "\f002";
@overlay-icon-size:                             50px;
@overlay-icon-color:                            #fff;
@overlay-fade-in-opacity:                       1;
@overlay-fade-out-opacity:                      0.7;
@overlay-scale-in-scale:                        1.1;
@overlay-scale-out-scale:                       1;
@overlay-spin-in-scale:                         1.1;
@overlay-spin-out-scale:                        1;
@overlay-spin-in-rotate:                        3deg;
@overlay-spin-out-rotate:                       0deg;
@overlay-panel-link-color:                      inherit;
@overlay-panel-link-text-decoration:            underline;
@overlay-area-background:                       rgba(0,0,0,0.3);
@overlay-area-icon:                             "\f002";
@overlay-area-icon-size:                        50px;
@overlay-area-icon-color:                       #fff;
@overlay-area-content-font-size:                1rem;
@overlay-area-content-padding-horizontal:       15px;
@overlay-area-content-color:                    #fff;
@overlay-area-content-link-color:               inherit;
@overlay-caption-background:                    rgba(0,0,0,0.5);
@overlay-caption-padding:                       15px;
@overlay-caption-color:                         #fff;
@pagination-font-size:                          1rem;
@pagination-margin-left:                        5px;
@pagination-padding-vertical:                   3px;
@pagination-padding-horizontal:                 5px;
@pagination-line-height:                        20px;
@pagination-background:                         #eee;
@pagination-color:                              #444;
@pagination-hover-background:                   #f5f5f5;
@pagination-hover-color:                        #444;
@pagination-onclick-background:                 #ddd;
@pagination-onclick-color:                      #444;
@pagination-active-background:                  #00a8e6;
@pagination-active-color:                       #fff;
@pagination-disabled-background:                #f5f5f5;
@pagination-disabled-color:                     #999;
@panel-title-margin-bottom:                     15px;
@panel-title-font-size:                         18px;
@panel-title-line-height:                       24px;
@panel-title-font-weight:                       normal;
@panel-title-color:                             #444;
@panel-title-text-transform:                    none;
@panel-teaser-margin-bottom:                    @panel-title-margin-bottom;
@panel-box-padding:                             15px;
@panel-box-background:                          #f5f5f5;
@panel-box-color:                               #444;
@panel-box-hover-color:                         @panel-box-color;
@panel-box-title-color:                         #444;
@panel-box-badge-top:                           10px;
@panel-box-badge-right:                         @panel-box-badge-top;
@panel-box-teaser-margin:                       -@panel-box-padding;
@panel-box-primary-background:                  #ebf7fd;
@panel-box-primary-color:                       #2d7091;
@panel-box-primary-hover-color:                 @panel-box-primary-color;
@panel-box-primary-title-color:                 #2d7091;
@panel-box-secondary-background:                #eee;
@panel-box-secondary-color:                     #444;
@panel-box-secondary-hover-color:               @panel-box-secondary-color;
@panel-box-secondary-title-color:               #444;
@panel-hover-padding:                           15px;
@panel-hover-color:                             #444;
@panel-hover-hover-background:                  #f5f5f5;
@panel-hover-hover-color:                       #444;
@panel-hover-badge-top:                         10px;
@panel-hover-badge-right:                       @panel-hover-badge-top;
@panel-hover-teaser-margin:                     -@panel-hover-padding;
@panel-header-title-padding:                    10px;
@panel-header-title-border:                     #ddd;
@panel-header-title-border-width:               1px;
@panel-header-title-color:                      #444;
@panel-space-padding:                           30px;
@panel-divider-gutter:                          25px;
@panel-divider-gutter-large:                    35px;
@panel-divider-border:                          #ddd;
@panel-divider-border-width:                    1px;
@subnav-margin-horizontal:                      10px;
@subnav-margin-vertical:                        @subnav-margin-horizontal;
@subnav-color:                                  #444;
@subnav-hover-color:                            #07D;
@subnav-hover-text-decoration:                  none;
@subnav-active-color:                           #07D;
@subnav-line-border-height:                     10px;
@subnav-line-border:                            #ddd;
@subnav-line-border-width:                      1px;
@subnav-pill-padding-vertical:                  3px;
@subnav-pill-padding-horizontal:                9px;
@subnav-pill-hover-background:                  #eee;
@subnav-pill-hover-color:                       #444;
@subnav-pill-active-background:                 #00a8e6;
@subnav-pill-active-color:                      #fff;
@subnav-disabled-color:                         #999;
@tab-border:                                    #ddd;
@tab-border-width:                              1px;
@tab-padding-horizontal:                        12px;
@tab-padding-vertical:                          8px;
@tab-padding-top:                               @tab-padding-vertical;
@tab-padding-bottom:                            @tab-padding-vertical;
@tab-margin-horizontal:                         5px;
@tab-margin-vertical:                           5px;
@tab-color:                                     #07D;
@tab-hover-border:                              #f5f5f5;
@tab-hover-background:                          #f5f5f5;
@tab-hover-color:                               #059;
@tab-active-border:                             #ddd;
@tab-active-background:                         #fff;
@tab-active-color:                              #444;
@tab-disabled-color:                            #999;
@table-margin-vertical:                         15px;
@table-padding-vertical:                        8px;
@table-padding-horizontal:                      8px;
@table-caption-color:                           #999;
@table-caption-font-size:                       12px;
@table-striped-background:                      #f5f5f5;
@table-condensed-padding-vertical:              4px;
@table-condensed-padding-horizontal:            8px;
@table-hover-background:                        #EEE;
@table-active-background:                       @table-hover-background;
@text-small-font-size:                          11px;
@text-small-line-height:                        16px;
@text-large-font-size:                          18px;
@text-large-line-height:                        24px;
@text-large-font-weight:                        normal;
@text-muted-color:                              #999;
@text-primary-color:                            #2d7091;
@text-success-color:                            #659f13;
@text-warning-color:                            #e28327;
@text-danger-color:                             #d85030;
@text-contrast-color:                           #fff;
@thumbnail-padding:                             4px;
@thumbnail-border:                              #ddd;
@thumbnail-border-width:                        1px;
@thumbnail-background:                          #fff;
@thumbnail-hover-border:                        #aaa;
@thumbnail-hover-background:                    #fff;
@thumbnail-caption-padding:                     @thumbnail-padding;
@thumbnail-caption-color:                       #444;
@thumbnail-mini-width:                          150px;
@thumbnail-small-width:                         200px;
@thumbnail-medium-width:                        300px;
@thumbnail-large-width:                         400px;
@utility-container-max-width:                   980px;
@utility-container-padding-horizontal:          25px;
@utility-container-large-max-width:             1200px;
@utility-container-large-padding-horizontal:    35px;
@utility-align-horizontal:                      15px;
@utility-align-vertical:                        15px;
@utility-height-viewport-min-height:            600px;
@utility-margin:                                15px;
@utility-margin-small:                          5px;
@utility-margin-large:                          50px;
@utility-border-rounded:                        5px;
@utility-heading-large-small-font-size:         36px;
@utility-heading-large-small-line-height:       42px;
@utility-heading-large-font-size:               52px;
@utility-heading-large-line-height:             64px;
@utility-link-muted-color:                      #444;
@utility-link-muted-hover-color:                #444;
@utility-scrollable-text-height:                300px;
@utility-scrollable-box-height:                 170px;
@utility-scrollable-box-padding:                10px;
@utility-scrollable-box-border:                 #ddd;
@utility-scrollable-box-border-width:           1px;
@breakpoint-small:                              480px;
@breakpoint-medium:                             768px;
@breakpoint-large:                              960px;
@breakpoint-xlarge:                             1200px;
@breakpoint-mini-max:                           (@breakpoint-small - 1);
@breakpoint-small-max:                          (@breakpoint-medium - 1);
@breakpoint-medium-max:                         (@breakpoint-large - 1);
@breakpoint-large-max:                          (@breakpoint-xlarge - 1);
@thumbnav-margin-horizontal:                    10px;
@thumbnav-margin-vertical:                      @thumbnav-margin-horizontal;
@thumbnav-background:                           #fff;
@thumbnav-opacity:                              0.7;
@thumbnav-hover-opacity:                        1;
@thumbnav-active-opacity:                       1;
