/* theme: Default */
/* UIKit LESS Addition for Boar's Head DOCK */

//
// General
//

@global-border: #c0beb4;

//
// Typography
//

@global-link-color: #5c8288;
@global-link-hover-color: #749fa6;

@base-link-color:                               #212223;
@base-link-hover-color:                         #a20f12;
@base-link-active-color:                         #749fa6;

//
// Buttons, Navs & Badges
//

@global-primary-background: #9c2b21;
@global-success-background: #66be31;
@global-warning-background: #eab049;
@global-danger-background: #c7101d;

//
// Alert
//

@alert-warning-color: #ffffff;
@alert-danger-color: #ffffff;
@alert-padding: 16px;
@alert-background: #eeeeee;
@alert-color: #212223;
@alert-success-background: #66be31;
@alert-success-color: #ffffff;
@alert-warning-background: #eab049;
@alert-danger-background: #c7101d;
@alert-large-padding: 32px;

//
// Button
//

@button-background: #cccccc;
@button-hover-background: #cccccc;
@button-active-background: #cccccc;
@button-primary-background: #9c2b21;
@button-primary-hover-background: #a20f12;
@button-primary-active-background: #7b2219;
@button-danger-hover-background: #c7101d;
@button-success-hover-background: #66be31;
@button-success-active-background: #66be31;

//
// Form
//

@form-danger-color: #c7101d;

//
// Icon
//

@icon-font-path: "../fonts";

//
// Offcanvas
//

@offcanvas-bar-width: 300px;
@offcanvas-background: rgba(0, 0, 0, 0.3);
@offcanvas-bar-background: #212223;

//
// Panel
//

@panel-box-primary-background: #f4efe9;
@panel-box-background: #dcd7cf;
@panel-box-primary-color: #52534c;
@panel-box-primary-title-color: #52534c;
@panel-box-secondary-background: #fff;
.uk-panel-box-secondary{
  border: 1px solid #c0beb4;
  border-radius: 2px;
}

//
// Text
//

@text-warning-color: #eab049;
@text-danger-color: #c7101d;
@text-success-color: #66be31;
@text-bh-red-color: #7b2219;
@text-bh-gold-color: #ab965d;


@block-default-background: #eeeeee;
@block-secondary-background: #f4efe9;
@base-hr-border: #e4e4e4;